import React, { SyntheticEvent } from 'react'
import Router from 'next/router'
import classNames from 'classnames'
import { AnimatePresence, motion, Variants } from 'framer-motion'
import { ExclamationCircleOutlined } from '@ant-design/icons'

import { recoilAction } from '@pig-common/recoils'
import { useCookies, COOKIES_KEY } from '@pig-common/utils/cookies'
import useSystemStatus from '@pig-common/hooks/useSystemStatus'
import { useQR, QRCodeInfo } from '@pig-common/hooks/useQrHook'
import {
  CheckPhoneResponse,
  CHECK_PHONE_RESULT,
  useCheckPhone,
} from '@pig-common/hooks/useCheckPhone'

import {
  AlertContactModal,
  AlertModal,
  ALERT_MODAL_TYPE,
} from '@pig-common/components/Modal'
import {
  InformationBox,
  INFO_BOX_VARIANT,
} from '@pig-common/components/InformationBox'

import DepositQRFirstStep from './DepositQRFirstStep'
import DepositQRSecondStep from './DepositQRSecondStep'

enum DEPOSIT_QR_STEP {
  SELECT_AMOUNT,
  GENERATE_QR_CODE,
}

const motionVariant: Variants = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
}

const DepositEWalletSection = () => {
  const [isLoading, setIsLoading] = React.useState(false)
  const [currentStep, setCurrentStep] = React.useState<DEPOSIT_QR_STEP>(0)
  const [isBlockRequestQR, setIsBlockRequestQR] = React.useState(false)
  const [openModal, setOpenModal] = React.useState(false)
  const [shouldShowContactModal, setShouldShowContactModal] =
    React.useState(false)
  const { refetch: refreshSystemStatus } = useSystemStatus({ enabled: false })
  const { callGenerateDepositQR } = useQR()
  const [alertModalType, setAlertModalType] = React.useState<ALERT_MODAL_TYPE>(
    ALERT_MODAL_TYPE.UNEXPECTED_BEHAVIORS,
  )
  const [redirectPath, setRedirectPath] = React.useState<string | undefined>()

  const [cookies, , removeCookies] = useCookies<string>([
    COOKIES_KEY.UID,
    COOKIES_KEY.CUSTOMER_CODE,
  ])
  const { callCheckPhone } = useCheckPhone()

  // NOTE : QR Code State(s)
  const [realAmount, setRealAmount] = React.useState(0.0)
  const [generatedQRInfo, setGeneratedQRInfo] = React.useState<QRCodeInfo>()
  const memoPhoneNumber = React.useMemo(() => cookies.uid, [])

  const blockRequestQRTitleText = 'คุณขอคิวอาร์โค้ดมากเกินไป'
  const blockRequestQRContentText =
    'กรุณารอประมาณ 10 นาที เพื่อเข้าสู่หน้าเติมเงินอีกครั้ง'

  const forceLogout = () => {
    recoilAction.removeAll()
    removeCookies(COOKIES_KEY.CFID)
    removeCookies(COOKIES_KEY.UID)
    removeCookies(COOKIES_KEY.CUSTOMER_CODE)
  }

  const showAdminModal = () => {
    setShouldShowContactModal(true)
    forceLogout()
    setOpenModal(true)
  }

  const showAlertModal = (type: ALERT_MODAL_TYPE, shouldLogout = true) => {
    setShouldShowContactModal(false)
    setAlertModalType(type)
    if (shouldLogout) forceLogout()
    setOpenModal(true)
  }

  const closeModalHandler = async () => {
    if (redirectPath) {
      if (redirectPath === '/reset-pin') {
        Router.push(
          { pathname: '/reset-pin', query: { phone: memoPhoneNumber } },
          '/reset-pin',
        )
      } else {
        await Router.push(redirectPath)
      }
    }
    setOpenModal(false)
  }

  const checkPhoneNumberStatus = async (response: CheckPhoneResponse) => {
    switch (response.data.phone_number_status) {
      case CHECK_PHONE_RESULT.ACTIVE:
        return true
      case CHECK_PHONE_RESULT.BLOCKED:
        // show block pin modal
        setRedirectPath('/reset-pin')
        showAlertModal(ALERT_MODAL_TYPE.PIN_LOCKED)
        return false
      case CHECK_PHONE_RESULT.DISABLED:
      case undefined:
        setRedirectPath('/login')
        showAdminModal()
        return false
      default:
        // show unexpected modal
        setRedirectPath('/login')
        showAlertModal(ALERT_MODAL_TYPE.SOMETHING_WENT_WRONG)
        return false
    }
  }

  const generateQRCode = async (originalAmount: number) => {
    setIsLoading(true)
    refreshSystemStatus()
    setRealAmount(originalAmount)
    try {
      // NOTE : request check phone api
      const checkphoneResponse = await callCheckPhone({
        phone: cookies.uid || '',
      })
      let isValidUser = false
      if (checkphoneResponse) {
        isValidUser = await checkPhoneNumberStatus(checkphoneResponse)
      }
      if (!isValidUser) return

      const qrResponse = await callGenerateDepositQR({ amount: originalAmount })

      if (qrResponse.service_code === 'BOE-2000') {
        setGeneratedQRInfo(qrResponse.data)
        setRealAmount(originalAmount)
        setCurrentStep(DEPOSIT_QR_STEP.GENERATE_QR_CODE)
        setIsBlockRequestQR(false)
        return
      }

      if (qrResponse.service_code === 'BOE-2001') {
        setCurrentStep(DEPOSIT_QR_STEP.GENERATE_QR_CODE)
        setIsBlockRequestQR(true)
      } else {
        throw Error('Request QR Code fail')
      }
    } catch (_error) {
      // NOTE : Show unexpected behaviors error modal
      showAlertModal(ALERT_MODAL_TYPE.SOMETHING_WENT_WRONG, false)
      setRedirectPath(undefined)
    } finally {
      setIsLoading(false)
    }
  }

  const regenerateQRCode = () => {
    generateQRCode(realAmount)
  }

  const sectionClassName = classNames('ps-deposit-qr', {
    'ps-deposit-qr--disabled': isBlockRequestQR,
  })

  const renderFirstStep = () => (
    <motion.form
      key="motion-qr-deposit-1"
      id="wallet-qr-deposit-step-1"
      className={sectionClassName}
      initial="initial"
      animate="animate"
      exit="exit"
      variants={motionVariant}
      onSubmit={(e: SyntheticEvent) => e.preventDefault()}
    >
      <DepositQRFirstStep
        onClickConfirmButton={generateQRCode}
        isLoading={isLoading}
      />
    </motion.form>
  )
  const renderSecoundStep = () => (
    <motion.div
      key="motion-qr-deposit-2"
      id="wallet-qr-deposit-step-2"
      className={sectionClassName}
      initial="initial"
      animate="animate"
      exit="exit"
      variants={motionVariant}
    >
      <DepositQRSecondStep
        qrInfo={{
          accountName: generatedQRInfo?.bank_name,
          qrCodeURL: generatedQRInfo?.qr_url,
          generatedAmount: generatedQRInfo?.generate_amount,
          expireAt: generatedQRInfo?.expired_at,
        }}
        isLoading={isLoading}
        onClickTopUpAgainButton={() =>
          setCurrentStep(DEPOSIT_QR_STEP.SELECT_AMOUNT)
        }
        onTapExpiredQRCode={regenerateQRCode}
        isBlockRequestQR={isBlockRequestQR}
        phoneNumber={memoPhoneNumber}
      />
    </motion.div>
  )
  return (
    <>
      {isBlockRequestQR && (
        <div className="ps-disable-request-qr">
          <InformationBox
            id="block-request-qr-information-box"
            width="100%"
            title={blockRequestQRTitleText}
            content={blockRequestQRContentText}
            prependContent={
              <ExclamationCircleOutlined style={{ marginLeft: 10 }} />
            }
            variant={INFO_BOX_VARIANT.INFO}
          />
        </div>
      )}
      <AnimatePresence exitBeforeEnter>
        {currentStep === DEPOSIT_QR_STEP.SELECT_AMOUNT && renderFirstStep()}
        {currentStep === DEPOSIT_QR_STEP.GENERATE_QR_CODE &&
          renderSecoundStep()}
      </AnimatePresence>
      {shouldShowContactModal ? (
        <AlertContactModal open={openModal} onClose={closeModalHandler} />
      ) : (
        <AlertModal
          open={openModal}
          onClose={closeModalHandler}
          type={alertModalType}
        />
      )}
    </>
  )
}

export default DepositEWalletSection
