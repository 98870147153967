import React, { useState } from 'react'
import router from 'next/router'
import { AnimatePresence, motion, Variants } from 'framer-motion'
import NiceModal from '@ebay/nice-modal-react'

import { profileStateCommon } from '@pig-common/recoils'
import { useRecoilValue } from 'recoil'
import useSystemStatus from '@pig-common/hooks/useSystemStatus'
import { DepositBankInfo } from '@pig-common/hooks/useDepositBank'

import { SelectCard } from '@pig-common/components/SelectCard'
import { ErrorNotice } from '@pig-common/components/ErrorNotice'
import checkUserType, { UserType } from '@pig-common/utils/checkUser'
import { AlertConfirmModal } from '@pig-common/components/Modal/AlertConfirmationModal/AlertConfirmationModal'
import { useMediaQuery } from '@pig-frontend/uikit'
import { TruemoneyDepositImage } from '@pig-common/constants/images'
import IconDepositEWallet from '@pig-common/components/Icon/IconDepositEWallet'
import IconDepositPromptPay from '@pig-common/components/Icon/IconDepositPromptPay'
import IconDepositBank from '../Icon/IconDepositBank'
import { Swiper, SwiperSlide } from 'swiper/react'
import { DEPOSIT_TYPE } from '../SelectCard/SelectCard'

export type DepositSectionProps = {
  title: string
  cardDetail: string[]
  qrSection?: React.ReactNode
  eWalletSection?: React.ReactNode
  transferSection?: React.ReactNode
  trueMoneySection?: React.ReactNode
  isLoading: boolean
  qrList?: DepositBankInfo[]
  bankList?: DepositBankInfo[]
  trueList?: DepositBankInfo[]
  qrErrorTitle: string
  qrErrorDetail: string
  transferErrorTitle: string
  transferErrorDetail: string
  trueMoneyErrorTitle: string
  trueMoneyErrorDetail: string
}

const variants: Variants = {
  hidden: { opacity: 0, position: 'absolute', display: 'none' },
  visible: { opacity: 1, position: 'relative', display: 'flex' },
}

const DepositSection = ({
  title,
  cardDetail,
  qrSection,
  eWalletSection,
  transferSection,
  trueMoneySection,
  isLoading,
  qrList,
  bankList,
  trueList,
  qrErrorTitle,
  qrErrorDetail,
  transferErrorTitle,
  transferErrorDetail,
  trueMoneyErrorTitle,
  trueMoneyErrorDetail,
}: DepositSectionProps) => {
  const isMD = useMediaQuery('md')
  const [select, setSelect] = useState(0)
  const { refetch: refreshSystemStatus } = useSystemStatus({ enabled: false })
  const profileInfo = useRecoilValue(profileStateCommon.profileState)
  const { lineUserId, phoneNumber, bankAccountNumber } = profileInfo
    ? profileInfo
    : {
        lineUserId: '',
        phoneNumber: '',
        bankAccountNumber: '',
      }
  const checkUserAction = (response: UserType, current: number) => {
    switch (response) {
      case UserType.PHONE_USER:
        if (current < 2) {
          router.push('/verify/verify-bank')
        } else {
          setSelect(current)
          refreshSystemStatus()
        }
        break
      case UserType.PHONE_BANK_USER:
        setSelect(current)
        refreshSystemStatus()
        break
      case UserType.LINE_USER:
        if (current === 2) {
          router.push('/verify/verify-phone-pin')
        } else {
          router.push('/verify/full-verify')
        }
        break
      case UserType.LINE_PHONE_USER:
        if (current < 2) {
          router.push('/verify/verify-bank')
        } else {
          setSelect(current)
          refreshSystemStatus()
        }
        break
      case UserType.LINE_PHONE_BANK_USER:
        setSelect(current)
        refreshSystemStatus()
        break
      default:
        setSelect(current)
        refreshSystemStatus()
    }
  }

  const onSelect = (current: number) => {
    const isSelectingTrueWallet = current === 2

    if (isSelectingTrueWallet && !profileInfo?.phoneNumber) {
      NiceModal.show(AlertConfirmModal, {
        open: true,
        header: 'เติมเงินด้วย ทรูมันนี่ วอลเล็ต',
        content: 'กรุณาตั้งค่าหมายเลขโทรศัพท์',
        buttonCloseText: 'ยกเลิก',
        buttonConfirmText: 'ตกลง',
        onClose: () => {
          router.back()
        },
        onConfirm: () => {
          router.push('/profile/set-phone', '/profile/set-phone', {
            scroll: true,
          })
        },
      })
    }
    if (current < 4) {
      checkUserAction(
        checkUserType(
          lineUserId !== '',
          phoneNumber || '',
          bankAccountNumber || '',
        ),
        current,
      )
    }
  }

  const getAnimate = (index: number) => {
    return select === index ? 'visible' : 'hidden'
  }

  return (
    <div className="ps-deposit-layout">
      <div>
        <div data-testid="deposit__title" className="ps-deposit-label">
          {title}
        </div>
        <Swiper slidesPerView={3.5} spaceBetween={8} slideToClickedSlide>
          <SwiperSlide key={'promptpay'}>
            <SelectCard
              cardDetail={cardDetail[0]}
              onSelect={onSelect}
              selected={select === 0}
              type={DEPOSIT_TYPE.PROMPTPAY}
            >
              <IconDepositPromptPay size={120} />
              {qrList &&
                qrList[0] &&
                qrList[0].Interval &&
                qrList[0].Interval < 10 && (
                  <div
                    style={{
                      position: 'absolute',
                      bottom: isMD ? 2 : '-4px',
                      left: 0,
                      right: 0,
                      textAlign: 'center',
                      color: '#0573F3',
                      fontSize: isMD ? 16 : 14,
                      marginTop: '2px',
                    }}
                  >
                    {`ภายใน ${qrList[0].Interval} นาที!`}
                  </div>
                )}
            </SelectCard>
          </SwiperSlide>
          <SwiperSlide key={'bank'}>
            <SelectCard
              cardDetail={cardDetail[1]}
              onSelect={onSelect}
              selected={select === 1}
              type={DEPOSIT_TYPE.BANK}
            >
              <IconDepositBank size={120} />
            </SelectCard>
          </SwiperSlide>
          <SwiperSlide key={'e-wallet'}>
            <SelectCard
              cardDetail={cardDetail[2]}
              onSelect={onSelect}
              selected={select === 2}
              type={DEPOSIT_TYPE.EWALLET}
            >
              <IconDepositEWallet size={120} />
            </SelectCard>
          </SwiperSlide>
          <SwiperSlide key={'true-money'}>
            <SelectCard
              cardDetail={cardDetail[3]}
              onSelect={onSelect}
              selected={select === 3}
              type={DEPOSIT_TYPE.TRUE_MONEY}
            >
              <TruemoneyDepositImage
                data-testid="select-true-money__image"
                className="ps-select-truemoney-icon"
              />
            </SelectCard>
          </SwiperSlide>
        </Swiper>
      </div>
      {isLoading ? (
        ''
      ) : (
        <AnimatePresence>
          <motion.div
            className="ps-deposit-banklist"
            initial="hidden"
            animate={getAnimate(0)}
            variants={variants}
            transition={{ duration: 0.3 }}
            key="promptpay"
          >
            {qrList && qrList.length !== 0 ? (
              qrSection
            ) : (
              <ErrorNotice title={qrErrorTitle} subTitle={qrErrorDetail} />
            )}
          </motion.div>
          <motion.div
            className="ps-deposit-banklist"
            initial="hidden"
            animate={getAnimate(1)}
            variants={variants}
            transition={{ duration: 0.3 }}
            key="bank"
          >
            {bankList && bankList.length !== 0 ? (
              transferSection
            ) : (
              <ErrorNotice
                title={transferErrorTitle}
                subTitle={transferErrorDetail}
              />
            )}
          </motion.div>
          {/* <motion.div
            className="ps-deposit-banklist"
            initial="hidden"
            animate={getAnimate(2)}
            variants={variants}
            transition={{ duration: 0.3 }}
            key="e-wallet"
          >
            {eWalletSection}
          </motion.div> */}
          <motion.div
            className="ps-deposit-banklist"
            initial="hidden"
            animate={getAnimate(3)}
            variants={variants}
            transition={{ duration: 0.3 }}
            key="true-money"
          >
            {trueList && trueList.length !== 0 ? (
              trueMoneySection
            ) : (
              <ErrorNotice
                title={trueMoneyErrorTitle}
                subTitle={trueMoneyErrorDetail}
              />
            )}
          </motion.div>
        </AnimatePresence>
      )}
    </div>
  )
}

export default DepositSection
