import classNames from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'
import React from 'react'
import { RecommendBadge } from '@pig-common/components/Icon/recommendBadge'

import { Card } from '../Card'

export enum DEPOSIT_TYPE {
  PROMPTPAY = 0,
  BANK = 1,
  EWALLET = 2,
  TRUE_MONEY = 3,
}

export type SelectCardProps = {
  children: React.ReactNode
  type: DEPOSIT_TYPE
  cardDetail?: string
  selected?: boolean
  onSelect?: (type: DEPOSIT_TYPE) => void
}

const SelectCard = ({
  children,
  cardDetail,
  selected,
  type,
  onSelect,
}: SelectCardProps) => {
  return (
    <div className="ps-select-card">
      <AnimatePresence>
        <div
          className={classNames('ps-select-card__layout', {
            'ps-select-card__layout--active': selected,
          })}
          key={`select-card-${type.toString()}`}
        >
          <Card
            className="ps-select-card__item"
            onClick={() => onSelect && onSelect(type)}
          >
            {type === DEPOSIT_TYPE.PROMPTPAY && <RecommendBadge />}
            {children}
            {selected && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.2 }}
              />
            )}
          </Card>
          {cardDetail ? (
            <div className="ps-select-card__detail">{cardDetail}</div>
          ) : (
            ''
          )}
        </div>
      </AnimatePresence>
    </div>
  )
}

export default SelectCard
