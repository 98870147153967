const IconDepositBank = ({ size = 16 }: { size?: number }): JSX.Element => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M46.5 66.5469C46.5 60.1094 46.5 53.6719 46.5 47.2344C47.5625 47.2344 48.6094 47.2188 49.6719 47.2188C50.6719 47.2188 51.6562 47.2344 52.6562 47.2344C52.6562 53.6719 52.6562 60.1094 52.6562 66.5312C51.6562 66.5312 50.6719 66.5469 49.6875 66.5469C48.6094 66.5625 47.5625 66.5625 46.5 66.5469Z"
        fill="white"
      />
      <path
        d="M49.6719 47.2188C48.6094 47.2188 47.5625 47.2344 46.5 47.2344C45.4844 45.8125 44.4531 44.3906 43.4375 42.9531C46.4688 42.9531 49.5156 42.9531 52.5469 42.9531C52.125 43.7656 51.5156 44.4531 51.0156 45.2188C50.5781 45.9062 50.0469 46.5156 49.6719 47.2188Z"
        fill="#6729FD"
      />
      <path
        d="M46.5 66.5469C47.5625 66.5469 48.6094 66.5469 49.6719 66.5625C50.0156 67.375 50.6406 68 51.1094 68.7344C51.5625 69.4062 52.0469 70.0625 52.5781 70.8125H43.5156C44.2344 69.6563 45.0781 68.5937 45.8437 67.4687C46.0625 67.1562 46.2813 66.8594 46.5 66.5469Z"
        fill="#6729FD"
      />
      <path
        d="M43.5156 70.7969H52.5781C52.0469 70.0469 51.5625 69.3906 51.1094 68.7188C50.625 68 50 67.375 49.6719 66.5469C50.6719 66.5469 51.6563 66.5312 52.6406 66.5312C52.8594 66.8438 53.0781 67.1406 53.2969 67.4531C54.0781 68.5781 54.8594 69.7031 55.6563 70.8281C54.6563 70.9531 53.6406 70.875 52.6406 70.875C49.7344 70.8906 46.8281 70.875 43.9219 70.875C43.7813 70.875 43.625 70.8438 43.4844 70.8281C43.5 70.8438 43.5 70.8125 43.5156 70.7969Z"
        fill="#310077"
      />
      <path
        d="M55.7188 42.9688C54.7031 44.3906 53.6719 45.8125 52.6563 47.25C51.6563 47.25 50.6719 47.2344 49.6719 47.2344C50.0469 46.5312 50.5781 45.9219 51.0156 45.25C51.5156 44.4844 52.125 43.7969 52.5469 42.9844C53.6094 42.9688 54.6563 42.9688 55.7188 42.9688Z"
        fill="#310077"
      />
      <path
        d="M24.8126 75.4689C24.2813 75.1564 24.0626 74.6876 24.1094 74.0626C24.1563 73.3751 24.1094 72.672 24.1251 71.9845C24.1407 71.2814 24.5157 70.8907 25.2344 70.8751C25.9063 70.8595 26.5782 70.8595 27.2657 70.8439C27.4063 70.8595 27.5626 70.8907 27.7032 70.8907C30.6094 70.8907 33.5157 70.9064 36.4219 70.8907C37.4219 70.8907 38.4376 70.9689 39.4376 70.8439C39.6407 70.8595 39.8282 70.8751 40.0313 70.8751C46.2344 70.8751 52.4376 70.8751 58.6563 70.8751C58.9688 70.8751 59.2813 70.8595 59.5938 70.8595C59.7188 70.8751 59.8594 70.9064 59.9844 70.9064C63.5782 70.9064 67.1719 70.9064 70.7813 70.8907C71.1251 70.8907 71.4688 70.9376 71.8126 70.8126C72.3594 70.8126 72.8907 70.8439 73.4376 70.8126C74.0469 70.7814 74.5469 70.9532 74.8907 71.4845V74.7501C74.7188 75.047 74.4844 75.2814 74.2032 75.4376L24.8126 75.4689Z"
        fill="#310077"
      />
      <path
        d="M74.7343 38.3125C74.7656 38.3125 74.8124 38.3125 74.8437 38.3125V41.1875C74.5312 42.0625 73.9999 42.7031 73.0624 42.9219C72.6406 43.0156 72.2031 42.9375 71.7812 42.9844C70.7499 42.9844 69.7187 42.9844 68.6874 42.9844C65.6406 42.9844 62.6093 42.9844 59.5624 42.9844C59.4999 42.9844 59.4374 42.9688 59.3593 42.9688C52.7187 42.9688 46.0937 42.9688 39.4531 42.9688C38.3906 42.9688 37.3437 42.9688 36.2812 42.9688C33.2499 42.9688 30.2031 42.9688 27.1718 42.9688C26.7187 42.9219 26.2499 43 25.7968 42.875C24.8437 42.6094 24.1406 41.7813 24.0937 40.8125C24.0624 40.0156 24.0937 39.2031 24.1093 38.4063C37.1093 38.4063 50.0937 38.4063 63.0937 38.4063C66.9843 38.3906 70.8593 38.4219 74.7343 38.3125Z"
        fill="#310077"
      />
      <path
        d="M74.1562 75.4687C74.4531 75.2969 74.6875 75.0625 74.8437 74.7812C74.8437 75.2344 74.6094 75.4687 74.1562 75.4687Z"
        fill="#E9F5FE"
      />
      <path
        d="M24.2969 38.2969C26.0938 37.2969 27.8906 36.2812 29.6875 35.2656C34.0781 32.8281 38.4844 30.375 42.875 27.9375C44.4531 27.0625 46.0156 26.2031 47.5938 25.3281C47.6719 25.5 47.8438 25.5312 47.9844 25.6094C49.9688 26.7031 51.9687 27.7969 53.9531 28.9062C56.7656 30.4687 59.5625 32.0469 62.3594 33.6094C64.6562 34.8906 66.9844 36.1406 69.2812 37.4375C69.75 37.7031 70.2656 37.8906 70.7031 38.3125H70.3438C55.1406 38.3125 39.9375 38.3125 24.7187 38.3125C24.5937 38.2969 24.4531 38.2969 24.2969 38.2969ZM52.25 32.125C52.25 30.5938 51 29.3281 49.4531 29.3281C48.0156 29.3125 46.7188 30.6406 46.7188 32.1094C46.7188 33.6094 48 34.9062 49.4844 34.9062C51 34.8906 52.25 33.625 52.25 32.125Z"
        fill="#6729FD"
      />
      <path
        d="M30.25 66.5469C30.25 60.1094 30.25 53.6719 30.25 47.2344C31.3125 47.2344 32.3594 47.2188 33.4219 47.2188C34.4219 47.2188 35.4062 47.2344 36.4062 47.2344C36.4062 53.6719 36.4062 60.1094 36.4062 66.5312C35.4062 66.5312 34.4219 66.5469 33.4219 66.5469C32.3594 66.5625 31.3125 66.5625 30.25 66.5469Z"
        fill="white"
      />
      <path
        d="M24.2969 38.2969C24.4375 38.2969 24.5938 38.3125 24.75 38.3125C39.9531 38.3125 55.1563 38.3125 70.375 38.3125H70.7344C70.2969 37.8906 69.7656 37.7031 69.3125 37.4375C67.0156 36.1406 64.7031 34.8906 62.3906 33.6094C59.5781 32.0469 56.7813 30.4688 53.9844 28.9063C52 27.7969 50 26.7188 48.0156 25.6094C47.875 25.5313 47.7031 25.5 47.625 25.3281C48.8438 24.5 50.0625 24.5469 51.3438 25.2656C54.7656 27.1875 58.2031 29.0781 61.6406 30.9844C65.9844 33.3906 70.3281 35.7969 74.6563 38.2031C74.7031 38.2188 74.7344 38.2656 74.7656 38.2969C70.8906 38.4063 67.0156 38.3906 63.1406 38.3906C50.1563 38.3906 37.1563 38.3906 24.1563 38.3906C24.1406 38.2969 24.2344 38.3125 24.2969 38.2969Z"
        fill="#310077"
      />
      <path
        d="M33.4219 47.2188C32.3594 47.2188 31.3125 47.2344 30.25 47.2344C29.2344 45.8125 28.2031 44.3906 27.1875 42.9531C30.2188 42.9531 33.2656 42.9531 36.2969 42.9531C35.875 43.7656 35.2656 44.4531 34.7656 45.2188C34.3281 45.9062 33.7969 46.5156 33.4219 47.2188Z"
        fill="#6729FD"
      />
      <path
        d="M30.25 66.5469C31.3125 66.5469 32.3594 66.5469 33.4219 66.5625C33.7656 67.375 34.3906 68 34.8594 68.7344C35.3125 69.4062 35.7969 70.0625 36.3281 70.8125H27.2656C27.9844 69.6563 28.8281 68.5937 29.5937 67.4687C29.8125 67.1562 30.0313 66.8594 30.25 66.5469Z"
        fill="#6729FD"
      />
      <path
        d="M27.2656 70.7969H36.3281C35.7969 70.0469 35.3125 69.3906 34.8594 68.7188C34.375 68 33.75 67.375 33.4219 66.5469C34.4219 66.5469 35.4063 66.5312 36.4063 66.5312C36.625 66.8438 36.8438 67.1406 37.0625 67.4531C37.8438 68.5781 38.625 69.7031 39.4219 70.8281C38.4219 70.9531 37.4063 70.875 36.4063 70.875C33.5 70.8906 30.5938 70.875 27.6875 70.875C27.5469 70.875 27.3906 70.8438 27.25 70.8281C27.25 70.8438 27.25 70.8125 27.2656 70.7969Z"
        fill="#310077"
      />
      <path
        d="M59.5625 70.8594C59.5625 70.8437 59.5781 70.8125 59.5937 70.7969C62.4531 70.7969 65.3281 70.7969 68.1875 70.7969C68.25 70.7969 68.3125 70.7969 68.3906 70.7969C68.5781 70.7812 68.625 70.7187 68.5156 70.5469C68.2656 70.2031 68.0156 69.8594 67.7812 69.5C67.125 68.5 66.3125 67.6094 65.75 66.5469C66.75 66.5312 67.7344 66.5312 68.7187 66.5156C69.7344 67.9375 70.7656 69.375 71.7812 70.7969C71.4531 70.9219 71.0937 70.875 70.75 70.875C67.1562 70.8906 63.5625 70.8906 59.9531 70.8906C59.8125 70.9062 59.6875 70.875 59.5625 70.8594Z"
        fill="#310077"
      />
      <path
        d="M62.5625 47.2344C61.5625 45.8281 60.5156 44.4375 59.5781 42.9844C62.625 42.9844 65.6563 42.9844 68.7031 42.9844C68.5156 43.25 68.3281 43.5156 68.1406 43.7813C67.3438 44.9375 66.5313 46.0781 65.7344 47.2188C64.6719 47.2188 63.6094 47.2187 62.5625 47.2344Z"
        fill="#6729FD"
      />
      <path
        d="M39.4688 42.9688C38.4531 44.3906 37.4219 45.8125 36.4063 47.25C35.4063 47.25 34.4219 47.2344 33.4219 47.2344C33.7969 46.5312 34.3281 45.9219 34.7656 45.25C35.2656 44.4844 35.875 43.7969 36.2969 42.9844C37.3594 42.9688 38.4063 42.9688 39.4688 42.9688Z"
        fill="#310077"
      />
      <path
        d="M65.7344 47.2188C66.5312 46.0781 67.3437 44.9219 68.1406 43.7813C68.3281 43.5156 68.5156 43.25 68.7031 42.9844C69.7344 42.9844 70.7656 42.9844 71.7969 42.9844C70.7656 44.4062 69.75 45.8437 68.7187 47.2656C67.7187 47.25 66.7188 47.2344 65.7344 47.2188Z"
        fill="#310077"
      />
      <path
        d="M65.7344 47.2188C66.7344 47.2344 67.7187 47.25 68.7187 47.25C68.7187 53.6719 68.7187 60.1094 68.7187 66.5312C67.7187 66.5469 66.7344 66.5469 65.75 66.5625C64.6875 66.5625 63.6406 66.5625 62.5781 66.5469C62.5781 60.1094 62.5781 53.6562 62.5781 47.2188C63.6094 47.2188 64.6719 47.2188 65.7344 47.2188Z"
        fill="white"
      />
      <path
        d="M52.25 32.1251C52.25 33.6408 50.9844 34.8908 49.4688 34.9064C47.9844 34.9064 46.7031 33.6095 46.7031 32.1095C46.7031 30.6408 48.0156 29.3126 49.4375 29.3283C51 29.3283 52.2656 30.5783 52.25 32.1251Z"
        fill="#310077"
      />
      <path
        d="M62.5625 66.5625C63.625 66.5625 64.6719 66.5625 65.7344 66.5781C66.2969 67.6406 67.1094 68.5312 67.7656 69.5312C68 69.8906 68.25 70.2344 68.5 70.5781C68.625 70.75 68.5625 70.8125 68.375 70.8281C68.3125 70.8281 68.25 70.8281 68.1719 70.8281C65.3125 70.8281 62.4375 70.8281 59.5781 70.8281C60.5781 69.3906 61.5781 67.9688 62.5625 66.5625Z"
        fill="#6729FD"
      />
      <path
        d="M46.5 66.5469C46.5 60.1094 46.5 53.6719 46.5 47.2344C47.5625 47.2344 48.6094 47.2188 49.6719 47.2188C50.6719 47.2188 51.6562 47.2344 52.6562 47.2344C52.6562 53.6719 52.6562 60.1094 52.6562 66.5312C51.6562 66.5312 50.6719 66.5469 49.6875 66.5469C48.6094 66.5625 47.5625 66.5625 46.5 66.5469Z"
        fill="#D0BDFF"
      />
      <path
        d="M55.7188 42.9688C54.7031 44.3906 53.6719 45.8125 52.6562 47.25C51.6562 47.25 50.6719 47.2344 49.6719 47.2344C48.6094 47.2344 47.5625 47.25 46.5 47.25C45.4844 45.8281 44.4531 44.4062 43.4375 42.9688C46.4687 42.9688 49.5156 42.9688 52.5469 42.9688C53.6094 42.9688 54.6562 42.9688 55.7188 42.9688Z"
        fill="#6729FD"
      />
      <path
        d="M55.6563 70.8438C54.6563 70.9688 53.6406 70.8906 52.6406 70.8906C49.7344 70.9063 46.8281 70.8906 43.9219 70.8906C43.7813 70.8906 43.625 70.8594 43.4844 70.8438C43.4844 70.8281 43.4844 70.8125 43.5 70.7969C44.2188 69.6406 45.0625 68.5781 45.8281 67.4531C46.0469 67.1406 46.2656 66.8438 46.4844 66.5312C47.5469 66.5312 48.5938 66.5313 49.6562 66.5469C50.6562 66.5469 51.6406 66.5312 52.625 66.5312C52.8438 66.8438 53.0625 67.1406 53.2813 67.4531C54.0938 68.5938 54.875 69.7188 55.6563 70.8438Z"
        fill="#6729FD"
      />
      <path
        d="M24.8126 75.4689C24.2813 75.1564 24.0626 74.6876 24.1094 74.0626C24.1563 73.3751 24.1094 72.672 24.1251 71.9845C24.1407 71.2814 24.5157 70.8907 25.2344 70.8751C25.9063 70.8595 26.5782 70.8595 27.2657 70.8439C27.4063 70.8595 27.5626 70.8907 27.7032 70.8907C30.6094 70.8907 33.5157 70.9064 36.4219 70.8907C37.4219 70.8907 38.4376 70.9689 39.4376 70.8439C39.6407 70.8595 39.8282 70.8751 40.0313 70.8751C46.2344 70.8751 52.4376 70.8751 58.6563 70.8751C58.9688 70.8751 59.2813 70.8595 59.5938 70.8595C59.7188 70.8751 59.8594 70.9064 59.9844 70.9064C63.5782 70.9064 67.1719 70.9064 70.7813 70.8907C71.1251 70.8907 71.4688 70.9376 71.8126 70.8126C72.3594 70.8126 72.8907 70.8439 73.4376 70.8126C74.0469 70.7814 74.5469 70.9532 74.8907 71.4845V74.7501C74.7188 75.047 74.4844 75.2814 74.2032 75.4376L24.8126 75.4689Z"
        fill="#310077"
      />
      <path
        d="M74.7343 38.3125C74.7656 38.3125 74.8124 38.3125 74.8437 38.3125V41.1875C74.5312 42.0625 73.9999 42.7031 73.0624 42.9219C72.6406 43.0156 72.2031 42.9375 71.7812 42.9844C70.7499 42.9844 69.7187 42.9844 68.6874 42.9844C65.6406 42.9844 62.6093 42.9844 59.5624 42.9844C59.4999 42.9844 59.4374 42.9688 59.3593 42.9688C52.7187 42.9688 46.0937 42.9688 39.4531 42.9688C38.3906 42.9688 37.3437 42.9688 36.2812 42.9688C33.2499 42.9688 30.2031 42.9688 27.1718 42.9688C26.7187 42.9219 26.2499 43 25.7968 42.875C24.8437 42.6094 24.1406 41.7813 24.0937 40.8125C24.0624 40.0156 24.0937 39.2031 24.1093 38.4063C37.1093 38.4063 50.0937 38.4063 63.0937 38.4063C66.9843 38.3906 70.8593 38.4219 74.7343 38.3125Z"
        fill="#310077"
      />
      <path
        d="M74.1562 75.4687C74.4531 75.2969 74.6875 75.0625 74.8437 74.7812C74.8437 75.2344 74.6094 75.4687 74.1562 75.4687Z"
        fill="#E9F5FE"
      />
      <path
        d="M24.2969 38.2969C26.0938 37.2969 27.8906 36.2812 29.6875 35.2656C34.0781 32.8281 38.4844 30.375 42.875 27.9375C44.4531 27.0625 46.0156 26.2031 47.5938 25.3281C47.6719 25.5 47.8438 25.5312 47.9844 25.6094C49.9688 26.7031 51.9687 27.7969 53.9531 28.9062C56.7656 30.4687 59.5625 32.0469 62.3594 33.6094C64.6562 34.8906 66.9844 36.1406 69.2812 37.4375C69.75 37.7031 70.2656 37.8906 70.7031 38.3125H70.3438C55.1406 38.3125 39.9375 38.3125 24.7187 38.3125C24.5937 38.2969 24.4531 38.2969 24.2969 38.2969ZM52.25 32.125C52.25 30.5938 51 29.3281 49.4531 29.3281C48.0156 29.3125 46.7188 30.6406 46.7188 32.1094C46.7188 33.6094 48 34.9062 49.4844 34.9062C51 34.8906 52.25 33.625 52.25 32.125Z"
        fill="#6729FD"
      />
      <path
        d="M30.25 66.5469C30.25 60.1094 30.25 53.6719 30.25 47.2344C31.3125 47.2344 32.3594 47.2188 33.4219 47.2188C34.4219 47.2188 35.4062 47.2344 36.4062 47.2344C36.4062 53.6719 36.4062 60.1094 36.4062 66.5312C35.4062 66.5312 34.4219 66.5469 33.4219 66.5469C32.3594 66.5625 31.3125 66.5625 30.25 66.5469Z"
        fill="#D0BDFF"
      />
      <path
        d="M24.2969 38.2969C24.4375 38.2969 24.5938 38.3125 24.75 38.3125C39.9531 38.3125 55.1563 38.3125 70.375 38.3125H70.7344C70.2969 37.8906 69.7656 37.7031 69.3125 37.4375C67.0156 36.1406 64.7031 34.8906 62.3906 33.6094C59.5781 32.0469 56.7813 30.4688 53.9844 28.9063C52 27.7969 50 26.7188 48.0156 25.6094C47.875 25.5313 47.7031 25.5 47.625 25.3281C48.8438 24.5 50.0625 24.5469 51.3438 25.2656C54.7656 27.1875 58.2031 29.0781 61.6406 30.9844C65.9844 33.3906 70.3281 35.7969 74.6563 38.2031C74.7031 38.2188 74.7344 38.2656 74.7656 38.2969C70.8906 38.4063 67.0156 38.3906 63.1406 38.3906C50.1563 38.3906 37.1563 38.3906 24.1563 38.3906C24.1406 38.2969 24.2344 38.3125 24.2969 38.2969Z"
        fill="#310077"
      />
      <path
        d="M39.4688 42.9688C38.4531 44.3906 37.4219 45.8125 36.4062 47.25C35.4062 47.25 34.4219 47.2344 33.4219 47.2344C32.3594 47.2344 31.3125 47.25 30.25 47.25C29.2344 45.8281 28.2031 44.4062 27.1875 42.9688C30.2187 42.9688 33.2656 42.9688 36.2969 42.9688C37.3594 42.9688 38.4062 42.9688 39.4688 42.9688Z"
        fill="#6729FD"
      />
      <path
        d="M39.4062 70.8438C38.4062 70.9688 37.3906 70.8906 36.3906 70.8906C33.4844 70.9063 30.5781 70.8906 27.6719 70.8906C27.5313 70.8906 27.375 70.8594 27.2344 70.8438C27.2344 70.8281 27.2344 70.8125 27.25 70.7969C27.9687 69.6406 28.8125 68.5781 29.5781 67.4531C29.7969 67.1406 30.0156 66.8438 30.2344 66.5312C31.2969 66.5312 32.3437 66.5313 33.4062 66.5469C34.4062 66.5469 35.3906 66.5312 36.3906 66.5312C36.6094 66.8438 36.8281 67.1406 37.0469 67.4531C37.8437 68.5938 38.625 69.7188 39.4062 70.8438Z"
        fill="#6729FD"
      />
      <path
        d="M71.7656 70.8281C71.4375 70.9531 71.0781 70.9062 70.7344 70.9062C67.1406 70.9219 63.5469 70.9219 59.9375 70.9219C59.8125 70.9219 59.6719 70.8906 59.5469 70.875C59.5469 70.8594 59.5469 70.8281 59.5781 70.8125C60.5781 69.3906 61.5625 67.9844 62.5469 66.5625C63.6094 66.5625 64.6562 66.5625 65.7187 66.5781C66.7187 66.5625 67.7031 66.5625 68.6875 66.5469C69.7187 67.9688 70.75 69.3906 71.7656 70.8281Z"
        fill="#6729FD"
      />
      <path
        d="M71.7812 42.9688C70.75 44.3906 69.7344 45.8281 68.7031 47.25C67.7031 47.2344 66.7187 47.2188 65.7187 47.2188C64.6562 47.2188 63.6094 47.2344 62.5469 47.2344C61.5469 45.8281 60.5 44.4375 59.5625 42.9844C62.6094 42.9844 65.6406 42.9844 68.6875 42.9844C69.7187 42.9688 70.75 42.9688 71.7812 42.9688Z"
        fill="#6729FD"
      />
      <path
        d="M65.7344 47.2188C66.7344 47.2344 67.7187 47.25 68.7187 47.25C68.7187 53.6719 68.7187 60.1094 68.7187 66.5312C67.7187 66.5469 66.7344 66.5469 65.75 66.5625C64.6875 66.5625 63.6406 66.5625 62.5781 66.5469C62.5781 60.1094 62.5781 53.6562 62.5781 47.2188C63.6094 47.2188 64.6719 47.2188 65.7344 47.2188Z"
        fill="#D0BDFF"
      />
      <path
        d="M52.25 32.1251C52.25 33.6408 50.9844 34.8908 49.4688 34.9064C47.9844 34.9064 46.7031 33.6095 46.7031 32.1095C46.7031 30.6408 48.0156 29.3126 49.4375 29.3283C51 29.3283 52.2656 30.5783 52.25 32.1251Z"
        fill="#310077"
      />
    </svg>
  )
}

export default IconDepositBank
