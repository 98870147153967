/* SUPER LEGACY CODE; PLEASE RESTRUCTURE IT 🙏 */
import { useEffect, useMemo, useState } from 'react'
import styled, { useTheme } from 'styled-components'
import { Collapse as AntdCollapse, Space, Spin, Statistic } from 'antd'
import { notification } from '@pig-frontend/uikit'
import parse from 'html-react-parser'
import Link from 'next/link'
import { InboxItem } from '@pig-common/recoils/modules/inbox'
import { IconSetting } from '@pig-common/components/Icon/IconSetting'
import { IconDeposit } from '@pig-common/components/Icon/IconDeposit'
import { IconWithdraw } from '@pig-common/components/Icon/IconWithdraw'
import { IconChampionCup } from '@pig-common/components/Icon/IconChampionCup'
import { IconKycFreeCredit } from '@pig-common/components/Icon/IconKycFreeCredit'
import { IconFirstDeposit } from '@pig-common/components/Icon/IconFirstDeposit'
import { IconReachTarget } from '@pig-common/components/Icon/IconReachTarget'
import { IconBonus } from '@pig-common/components/Icon/IconBonus'
import { IconDailySpin } from '@pig-common/components/Icon/IconDailySpin'
import { INCOME_TABS, TABS } from '@pig-common/hooks/useAffiliate'
import { useCookies } from '@pig-common/utils/cookies'
import { Button, BUTTON_TYPE } from '@pig-common/components/Button'
import { useInbox } from '@pig-common/hooks/useInbox'
import { PIG_WEB_URL } from '@pig-common/models/buildtime-constant'
import dayjs from 'dayjs'
import { useRouter } from 'next/router'
import Timer, {
  maptoTimeData,
  TIMER_TYPE,
} from '@pig-common/components/DailyQuests/Timer'
import { pageLinkCommon } from '@pig-common/constants/page-name'
import isFunction from 'lodash/isFunction'
import useClaimCommission from '@pig-common/hooks/useClaimCommission'
import head from 'lodash/head'
import last from 'lodash/last'
import useBalance from '@pig-casino/hooks/useBalance'
import useClaimLosingStreak from '@pig-common/hooks/useClaimLosingStreak'

const Panel = styled(AntdCollapse.Panel)<{ read?: boolean }>`
  text-align: left;
  ${({ read }) =>
    read ? 'background-color: #F6F6F6;' : 'background-color: #F3ECFD;'}
  border-radius: 4px;
  & .ant-collapse-content-box > div {
    line-height: 1;
    font-size: 20px;
    font-weight: 400;
    padding-top: 12px;
    border-top: 1px solid ${({ theme }) => theme?.['color-greyscale-200']};
  }
`

const InboxTitle = styled.div`
  line-height: 1;
`

const InboxAt = styled.div`
  line-height: 1;
  font-size: 16px;
  color: ${({ theme }) => theme['color-greyscale-300']};
`

const InboxIconWrapper = styled.div`
  border-radius: 50%;
  width: 40px;
  height: 40px;
  text-align: center;
`

export type IInboxProps = InboxItem & {
  renderContent?: () => JSX.Element
  renderIcon?: () => JSX.Element
  readCookiesUntil?: Date
}

const CashbackButton: React.FunctionComponent<IInboxProps> = ({
  uid,
  end,
  start,
}) => {
  const { claimCashback } = useInbox()
  const secondDiff: number = dayjs(end).diff(dayjs(), 'seconds')

  const generateCashBackContent = () => {
    return secondDiff > 0 ? (
      <Button
        type={BUTTON_TYPE.CTA_1}
        onClick={() => {
          claimCashback(uid || '')
        }}
      >
        <Space>
          รับภายใน
          <Timer
            time={maptoTimeData(secondDiff)}
            variants={TIMER_TYPE.COMPACT_DATE}
            style={{ margin: 0 }}
          />
        </Space>
      </Button>
    ) : (
      <div style={{ color: '#EF5350' }}>
        {`การคืนยอดเสียของรอบวันที่\n${dayjs(start).format(
          'DD/MM/YY (HH:mm)',
        )} - ${dayjs(end).format('DD/MM/YY (HH:mm)')} หมดอายุแล้ว`}
      </div>
    )
  }
  return generateCashBackContent()
}

const InboxComponent = (props: IInboxProps) => {
  const { uid, date, type, title, subTitle, content, isRead, end } = props
  const claimDate = dayjs(end).add(7, 'hour')
  const router = useRouter()
  const [, setCookies] = useCookies([uid || ''])
  const { readInbox } = useInbox()
  const {
    mutate: claimCommission,
    data: claimedCommissionData,
    isLoading: isClaimingCommission,
  } = useClaimCommission()
  const {
    mutate: claimLosingStreak,
    data: claimedLosingStreakData,
    isLoading: isClaimingLosingStreak,
  } = useClaimLosingStreak()
  const { refetchBalance } = useBalance({})
  const [isHighlight, setHightlight] = useState<boolean>(isRead)
  const theme: any = useTheme()
  const dateValue = dayjs(claimDate)
  const diffDay = dateValue.diff(dayjs(), 'days')
  const [claimTimeout, setClaimTimeout] = useState<boolean>(
    dateValue.diff(dayjs(), 'seconds') <= 0,
  )
  const parseObjectInString = (contentStr: string) => {
    if (new RegExp('^{.*}$').test(contentStr)) {
      try {
        return JSON.parse(contentStr?.replace(/[']/g, '"'))?.component
      } catch (error) {
        return contentStr
      }
    }
    return contentStr
  }

  const parsedContent = useMemo(() => {
    return parseObjectInString(content || '')
  }, [content])

  useEffect(() => {
    if (claimedLosingStreakData?.serviceCode === 'PIG-3030') {
      refetchBalance()
    }

    // Handle error of lost-bet's claiming
    if (
      claimedLosingStreakData &&
      claimedLosingStreakData?.serviceCode !== 'PIG-3030'
    ) {
      if (claimedLosingStreakData?.serviceCode === 'PIG-3031') {
        notification.error({
          message: 'หมดเวลารับโปรแทงเสีย 12 ไม้',
          duration: 5,
        })
      } else if (claimedLosingStreakData?.serviceCode === 'PIG-4015') {
        notification.error({
          message: 'ไม่พบข้อมูล wallet หรือ user',
          duration: 5,
        })
      }
    }
  }, [claimedLosingStreakData])

  useEffect(() => {
    if (claimedCommissionData?.serviceCode === 'PIG-2030') {
      refetchBalance()
    }

    // Handle error of commission's claiming
    if (
      claimedCommissionData &&
      claimedCommissionData?.serviceCode !== 'PIG-2030'
    ) {
      if (claimedCommissionData?.serviceCode === 'PIG-2031') {
        notification.error({
          message: 'หมดเวลารับค่าคอมมิชชั่น',
          duration: 5,
        })
      } else if (claimedCommissionData?.serviceCode === 'PIG-4015') {
        notification.error({
          message: 'ไม่พบข้อมูล wallet หรือ user',
          duration: 5,
        })
      }
    }
  }, [claimedCommissionData])

  const handleChange = () => {
    if (uid && type === 'system') {
      setCookies(uid, date)
    }
  }

  return (
    <AntdCollapse
      key={`${title}${date}`}
      className="ps-transaction-collapse"
      bordered={false}
      onChange={(value) => {
        const expanded = value?.length > 0
        if (expanded && !isRead) {
          readInbox([props])
          setHightlight(true)
        }
        handleChange()
      }}
    >
      <Panel
        key="1"
        read={isHighlight}
        header={
          <Space>
            <InboxIconWrapper>
              {props?.icon &&
                parse(props?.icon, {
                  replace: (domNode: any) => {
                    if (domNode?.type !== 'tag') return null
                    const IconComponents = {
                      iconcashback: IconBonus,
                      iconsetting: IconSetting,
                      icondeposit: IconDeposit,
                      iconwithdraw: IconWithdraw,
                      iconchampioncup: IconChampionCup,
                      iconkycfreecredit: IconKycFreeCredit,
                      iconfirstdeposit: IconFirstDeposit,
                      iconreachtarget: IconReachTarget,
                      icondailyspin: IconDailySpin,
                    } as any
                    const Icon = IconComponents?.[domNode?.name]
                    if (Icon) return <Icon />
                    return null
                  },
                })}
            </InboxIconWrapper>
            <div>
              <InboxTitle>{parse(title || '')}</InboxTitle>
              <InboxAt>
                {parse(subTitle || '', {
                  replace: (domNode: any) => {
                    if (domNode?.type !== 'tag') return null
                    const colors = {
                      green: theme?.['color-green-400'],
                      red: theme?.['color-red-400'],
                    } as any
                    const color = colors?.[domNode?.name]
                    if (color)
                      return (
                        <span style={{ color }}>
                          {domNode?.children?.map((child: any) => child?.data)}
                        </span>
                      )
                    return null
                  },
                })}{' '}
                {head(content) === '{' &&
                  last(content) === '}' &&
                  parsedContent?.toLowerCase()?.includes('losingstreak') &&
                  (JSON.parse(content || '')?.is_claim ||
                    claimedLosingStreakData?.serviceCode === 'PIG-3030') && (
                    <span className="ps-transaction-collapse--received">
                      รับแล้ว
                    </span>
                  )}
                {head(content) === '{' &&
                  last(content) === '}' &&
                  parsedContent?.includes('commission') &&
                  (JSON.parse(content || '')?.is_claim ||
                    claimedCommissionData?.serviceCode === 'PIG-2030') && (
                    <span className="ps-transaction-collapse--received">
                      รับแล้ว
                    </span>
                  )}
              </InboxAt>
            </div>
          </Space>
        }
      >
        {content &&
          parse(parsedContent, {
            replace: (domNode: any) => {
              if (domNode?.type === 'tag') {
                const tagName = domNode?.name
                const children = domNode?.children?.map(
                  (child: any) => child?.data,
                )
                const isCasino = content.includes('จากค่ายเกม')
                if (tagName === 'dailyspin') {
                  return (
                    <Space style={{ width: '100%', justifyContent: 'center' }}>
                      <Button
                        type={BUTTON_TYPE.CTA_1}
                        className="ps-button ps-button--sm"
                        onClick={() => router.push(pageLinkCommon.daily.spin)}
                      >
                        <div style={{ padding: '5px 30px' }}>สปินทันที</div>
                      </Button>
                    </Space>
                  )
                }
                if (tagName?.toLowerCase()?.includes('losingstreak')) {
                  let parsedContent = ''
                  try {
                    parsedContent = JSON.parse(content?.replace(/[']/g, '"'))
                  } catch (error) {
                    console.error('[SYSTEM] cannot parse content inbox')
                  }
                  return (
                    <>
                      {(parsedContent as any)?.is_claim ||
                      claimedLosingStreakData?.serviceCode === 'PIG-3030' ? (
                        <Space
                          style={{ width: '100%', justifyContent: 'center' }}
                          direction="vertical"
                        >
                          <p className="ps-transaction-collapse--commission-description">
                            {title}
                          </p>
                          <p className="ps-transaction-collapse--commission-description">
                            ดูรายละเอียด{' '}
                            <Link
                              href={`${pageLinkCommon.wallet}?target=history`}
                            >
                              ประวัติเติม/ถอน
                            </Link>
                          </p>
                        </Space>
                      ) : (
                        <Space
                          style={{ width: '100%', justifyContent: 'center' }}
                        >
                          <Spin spinning={isClaimingLosingStreak}>
                            <Button
                              type={BUTTON_TYPE.CTA_1}
                              onClick={() =>
                                claimLosingStreak(
                                  (parsedContent as any)?.losing_streak_uid,
                                )
                              }
                              className="ps-button ps-button--sm"
                              disabled={
                                claimTimeout || (parsedContent as any)?.is_claim
                              }
                            >
                              <div
                                style={{
                                  padding: '5px 30px',
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: 5,
                                }}
                              >
                                {claimTimeout ? (
                                  'หมดอายุ'
                                ) : (
                                  <>
                                    {diffDay < 1 ? (
                                      <>
                                        รับภายใน{' '}
                                        <Statistic.Countdown
                                          onFinish={() => setClaimTimeout(true)}
                                          valueStyle={{
                                            color: 'white',
                                            fontSize: 20,
                                          }}
                                          value={dateValue?.unix() * 1000}
                                        />
                                      </>
                                    ) : (
                                      <>รับภายใน {diffDay} วัน</>
                                    )}
                                  </>
                                )}
                              </div>
                            </Button>
                          </Spin>
                        </Space>
                      )}
                    </>
                  )
                }
                if (tagName?.includes('commission')) {
                  let parsedContent = ''
                  try {
                    parsedContent = JSON.parse(content)
                  } catch (error) {
                    console.error('[SYSTEM] cannot parse content inbox')
                  }
                  return (
                    <>
                      {(parsedContent as any)?.is_claim ||
                      claimedCommissionData?.serviceCode === 'PIG-2030' ? (
                        <Space
                          style={{ width: '100%', justifyContent: 'center' }}
                          direction="vertical"
                        >
                          <p className="ps-transaction-collapse--commission-description">
                            {`ได้รับเงิน ${
                              title?.split(' ')?.[1]
                            } บาท โปรค่าคอมบาคาร่า`}
                          </p>
                          <p className="ps-transaction-collapse--commission-description">
                            ดูรายละเอียด{' '}
                            <Link
                              href={`${pageLinkCommon.wallet}?target=history`}
                            >
                              ประวัติเติม/ถอน
                            </Link>
                          </p>
                        </Space>
                      ) : (
                        <Space
                          style={{ width: '100%', justifyContent: 'center' }}
                        >
                          <Spin spinning={isClaimingCommission}>
                            <Button
                              type={BUTTON_TYPE.CTA_1}
                              onClick={() => claimCommission()}
                              className="ps-button ps-button--sm"
                              disabled={
                                claimTimeout || (parsedContent as any)?.is_claim
                              }
                            >
                              <div
                                style={{
                                  padding: '5px 30px',
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: 5,
                                }}
                              >
                                {claimTimeout ? (
                                  'หมดอายุ'
                                ) : (
                                  <>
                                    {diffDay < 1 ? (
                                      <>
                                        รับภายใน{' '}
                                        <Statistic.Countdown
                                          onFinish={() => setClaimTimeout(true)}
                                          valueStyle={{
                                            color: 'white',
                                            fontSize: 20,
                                          }}
                                          value={dateValue?.unix() * 1000}
                                        />
                                      </>
                                    ) : (
                                      <>รับภายใน {diffDay} วัน</>
                                    )}
                                  </>
                                )}
                              </div>
                            </Button>
                          </Spin>
                        </Space>
                      )}
                    </>
                  )
                }
                if (tagName === 'claimbutton')
                  return <CashbackButton {...props} />
                const paths = {
                  wallethistorypage: `${pageLinkCommon.wallet}?target=history`,
                  affiliatehistorypage: `${pageLinkCommon.affiliate}?target=${TABS.INCOME}&incomeTab=${INCOME_TABS.HISTORY}`,
                  dailyquestspage: isCasino
                    ? pageLinkCommon?.dailyQuests
                    : `${PIG_WEB_URL}/daily-quests`,
                  affiliatepage: `${pageLinkCommon?.affiliate}?target=${TABS.INCOME}`,
                } as any
                const path = paths?.[tagName]
                if (path) {
                  return (
                    <Link href={path}>
                      <>{children}</>
                    </Link>
                  )
                }
              }
              return null
            },
          })}
        {props?.renderContent &&
          isFunction(props?.renderContent) &&
          props?.renderContent()}
      </Panel>
    </AntdCollapse>
  )
}

export default InboxComponent
