const IconDepositPromptPay = ({
  size = 16,
}: {
  size?: number
}): JSX.Element => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_3689_8243)">
        <path
          d="M49.8985 100.084C77.5118 100.084 99.8969 77.6992 99.8969 50.0858C99.8969 22.4725 77.5118 0.0874023 49.8985 0.0874023C22.2851 0.0874023 -0.0999756 22.4725 -0.0999756 50.0858C-0.0999756 77.6992 22.2851 100.084 49.8985 100.084Z"
          fill="white"
        />
        <path
          d="M53.4391 72.9874L53.4797 72.9155H34.9016C29.4531 72.9155 24.9969 68.4749 24.9969 63.0483V37.9077C24.9969 32.4812 29.4531 28.0405 34.9016 28.0405H60.1406C64.0109 28.4483 66.0984 29.9624 67.1375 30.939C72.2625 35.7577 72.1219 46.5577 65.5688 57.0624C72.0078 61.453 78.4453 65.8452 84.8844 70.2358C84.8844 71.114 84.8844 71.9937 84.8844 72.8718C74.4031 72.9093 63.9219 72.9483 53.4391 72.9858V72.9874Z"
          fill="#310077"
        />
        <path
          d="M71.0812 57.2858C71.0594 50.6733 71.0359 44.0608 71.014 37.4515C70.9922 36.5546 70.8109 33.8311 68.7687 31.4343C66.6515 28.9546 63.7906 28.2999 62.0093 28.153V28.0405H38.2078C37.3109 28.0624 34.5875 28.2437 32.1906 30.289C29.675 32.4343 29.039 35.3452 28.9015 37.1218H28.8656V60.9249C28.8875 61.8218 29.0703 64.5452 31.114 66.939C34.2406 70.6046 38.9844 70.2796 39.4937 70.2374H84.8844C80.2828 65.9202 75.6812 61.603 71.0812 57.2858ZM52.2203 32.6952H60.3875V32.9093C60.8953 32.9046 62.5797 32.9499 64.1719 34.1702C65.739 35.3718 66.2265 36.9546 66.3578 37.4499V46.4155C63.7094 46.378 61.0625 46.3405 58.414 46.303V42.6061C58.414 41.4858 57.4969 40.5687 56.3765 40.5687H52.2187V32.6952H52.2203ZM47.3172 65.4624H39.4922C38.9844 65.4671 37.2984 65.4233 35.7078 64.2015C34.139 62.9999 33.6515 61.4186 33.5218 60.9233V51.2499H41.175V55.7718C41.175 56.8921 42.0922 57.8093 43.2125 57.8093H47.3172V65.4624ZM47.3172 40.5687H43.2125C42.0922 40.5687 41.175 41.4858 41.175 42.6061V46.4561H33.5218V38.6655H33.664C33.6593 38.1577 33.7047 36.4733 34.925 34.8812C36.1265 33.314 37.7093 32.8265 38.2047 32.6952H47.3156V40.5687H47.3172ZM52.1609 57.8093H56.3781C57.4984 57.8093 58.4156 56.8921 58.4156 55.7718V51.664C63.1531 56.2624 67.8922 60.864 72.6297 65.4624H52.1609V57.8093Z"
          fill="#6729FD"
        />
        <path
          d="M72.6297 65.464H52.1609V57.8108C53.6688 57.803 55.175 57.7952 56.6828 57.7874C56.8391 57.7624 57.4672 57.6468 57.9422 57.0749C58.3906 56.5358 58.4141 55.9405 58.4156 55.7733V51.6655C63.1531 56.2655 67.8922 60.864 72.6297 65.464Z"
          fill="white"
        />
        <path
          d="M52.2203 32.6953H60.3875C60.9656 32.7313 62.6188 32.9094 64.1719 34.1703C65.6656 35.3828 66.1922 36.8875 66.3578 37.45V46.4156C63.7094 46.3781 61.0625 46.3406 58.4141 46.3031V42.6062C58.4141 41.4859 57.4969 40.5688 56.3766 40.5688H52.2188V32.6953H52.2203Z"
          fill="white"
        />
        <path
          d="M47.3172 65.464H39.4922C38.9844 65.4687 37.2984 65.4249 35.7078 64.203C34.139 63.0015 33.6515 61.4202 33.5219 60.9249V51.2515H41.175V55.7733C41.175 56.8937 42.0922 57.8108 43.2125 57.8108H47.3172V65.464Z"
          fill="white"
        />
        <path
          d="M47.3172 40.5688H43.2125C42.0922 40.5688 41.175 41.4859 41.175 42.6062V46.4563H33.5219V38.6656C33.5672 38.2875 33.6547 37.7719 33.8359 37.1797C33.9594 36.7719 34.2594 35.7516 34.9265 34.8813C36.1281 33.3141 37.7109 32.8266 38.2062 32.6953H47.3172V40.5688Z"
          fill="white"
        />
        <path
          d="M56.4687 40.6406H42.2953C41.2598 40.6406 40.4203 41.4801 40.4203 42.5156V56.6891C40.4203 57.7246 41.2598 58.5641 42.2953 58.5641H56.4687C57.5043 58.5641 58.3437 57.7246 58.3437 56.6891V42.5156C58.3437 41.4801 57.5043 40.6406 56.4687 40.6406Z"
          fill="#310077"
        />
      </g>
      <defs>
        <clipPath id="clip0_3689_8243">
          <rect width="100" height="100" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default IconDepositPromptPay
